export const  projects = `
  {
    "projects": [
      {
        "title": "Fernsteuerung Inkubator - Cytomat 10",
        "from": "09/2013",
        "to": "08/2014",
        "industry": "Laborautomation",
        "roles": [
          {
            "role": "Entwickler",
            "role": "Software Architect"
          }
        ],
        "description": "In diesem Projekt wird die Produktreihe „Cytomat 10“ in seiner Displaytechnologie aktualisiert. Das Gerät erhält ein 10 Zoll Farb-Touchscreen in Verbund mit einem Windows CE Embedded Gerät. Das Embedded Gerät kommuniziert über den I2C Bus mit der Hardware. Die Benutzeroberfläche wird als Windows CE Anwendung implementiert. Zusätzlich erhält die Anwendung eine Serverschnittstelle, die über Sockets eine Server/Client Kommunikation ermöglicht. Über diese Schnittstelle steuert eine Android Applikation den Inkubator, welche ebenfalls Bestandteil des Projektes ist.",
        "tasks": [
          {
            "task": "C# Programmierung in Visual Studio mit Schwerpunkt Netzwerkkommunikation Server/Client auf einem Cortex A8 Windows Embedded CE Gerät"
          },
          {
            "task": "Gestaltung einer komplexen und dynamischen Benutzeroberfläche und Android Applikation unter Anwendung von Android SDK und Eclipse"
          },
          {
            "task": "Teilnahme an Besprechung und Mitwirkung bei der Erstellung des Lasten- und Pflichtenheftes und Projektplan für das Projekt „neue Displaytechnologie für die Produktgruppe der Cytomaten“"
          }
        ],
        "techs": [
          {
            "tech": "C#"
          },
          {
            "tech": "Java Android SDK"
          },
          {
            "tech": "Visual Studio"
          },
          {
            "tech": "Android Studio"
          }
        ]
      },
      {
        "title": "Prototypenvergleich in C# und Java für den neuen Abrechnungsprozess",
        "from": "09/2014",
        "to": "06/2015 ",
        "industry": "Öffentlicher Dienst",
        "roles": [
          {
            "role": "WebMethods Engineer"
          },
          {
            "role": "Solution Architect"
          },
          {
            "role": "Technical Analyst"
          },
          {
            "role": "Entwickler"
          }
        ],
        "description": "In diesem Projekt soll einer der Kernprozesse der KV Hessen von Grund auf überdacht, erneuert und digitalisiert werden, um Altlasten und Medienbrüche zu beseitigen. Ebenso sollen Fehler durch menschlichen Eingriff minimiert und der Ablauf beschleunigt werden. In diesem Prozess fließen Quartalsweise ca. 450 Millionen € von den Krankenkassen über die KV-Hessen zu den Kassenärzten in Hessen. Der Prozess sichert die Vergütung der Leistungen, die an den Patienten in einem Quartal erbracht werden. Im ersten Schritt müssen die eingereichten Leistungen in Form einer Abrechnungsdatei syntaktisch und semantisch geprüft werden. Danach folgt die Validierung der Leistungen, welche nach strengen Regeln abläuft, die sich alle Quartale ändern können. Dazu werden verschiedenen und sehr komplexe Regelwerk aufgesetzt. Nach erfolgreichem Durchlauf der verschiedenen Prozesse müssen die validen Leistungen in Geld umgewandelt werden, was nach fixen und variablen Budgets geschieht. Dies ist ebenfalls sehr komplex. Nach dem Durchlaufen der verschiedenen Validierungen und Budgetierungen wird das errechnete Geld ausgezahlt.",
        "tasks": [
          {
            "task": "Technische Evaluierung von Schlüssel Softwarekomponenten in jeweils C#, Java und WebMethods Suite"
          },
          {
            "task": "Vergleich der Erzeugnisse bezüglich Performance und Integration in die neue Systemlandschaft"
          },
          {
            "task": "Logik-Entwicklung auf Glassfish-Applikationserver"
          },
          {
            "task": "Logikentwicklung mit WebMethods Flow Service"
          },
          {
            "task": "BPMN Abläufe für die WebMethods Process Engine erstellen"
          },
          {
            "task": "Präsentation von Ergebnissen vor der IT-Abteilungsleitung"
          }
        ],
        "techs": [
          {
            "tech": "C#"
          },
          {
            "tech": "JavaEE"
          },
          {
            "tech": "WebMethods Flow"
          },
          {
            "tech": "Eclipse Link"
          },
          {
            "tech": "BPMN"
          },
          {
            "tech": "WebMethods Suite"
          },
          {
            "tech": "Eclipse"
          },
          {
            "tech": "Visual Studio"
          },
          {
            "tech": "Jira"
          },
          {
            "tech": "Confluence"
          }
        ]
      },
      {
        "title": "Businessanalyse für die Digitalisierung des Abrechnungsprozesses. Erarbeitung und Modellierung von Ist und Sollprozessen",
        "from": "07/2015",
        "to": "05/2016 ",
        "industry": "Öffentlicher Dienst",
        "roles": [
          {
            "role": "Business-Analyst"
          }
        ],
        "description": "In diesem Projekt soll einer der Kernprozesse der KV Hessen von Grund auf überdacht, erneuert und digitalisiert werden, um Altlasten und Medienbrüche zu beseitigen. Ebenso sollen Fehler durch menschlichen Eingriff minimiert und der Ablauf beschleunigt werden. In diesem Prozess fließen Quartalsweise ca. 450 Millionen € von den Krankenkassen über die KV-Hessen zu den Kassenärzten in Hessen. Der Prozess sichert die Vergütung der Leistungen, die an den Patienten in einem Quartal erbracht werden. Im ersten Schritt müssen die eingereichten Leistungen in Form einer Abrechnungsdatei syntaktisch und semantisch geprüft werden. Danach folgt die Validierung der Leistungen, welche nach strengen Regeln abläuft, die sich alle Quartale ändern können. Dazu werden verschiedenen und sehr komplexe Regelwerk aufgesetzt. Nach erfolgreichem Durchlauf der verschiedenen Prozesse müssen die validen Leistungen in Geld umgewandelt werden, was nach fixen und variablen Budgets geschieht. Dies ist ebenfalls sehr komplex. Nach dem Durchlaufen der verschiedenen Validierungen und Budgetierungen wird das errechnete Geld ausgezahlt.",
        "tasks": [
          {
            "task": "Organisation und Führen von Workshops mit diversen Fachbereichen des Unternehmens und externen Beratern"
          },
          {
            "task": "Erarbeitung von Ist Prozessen innerhalb der Workshops"
          },
          {
            "task": "Modellierung der Ergebnisse in ARIS in Form von BPMNs – Business Blueprint"
          },
          {
            "task": "Erstellung und Modellierung von Soll Prozessen anhand der Ergebnisse der Workshop"
          },
          {
            "task": "Erstellung von fachlichem Datenmodell für die Prozesse"
          },
          {
            "task": "Präsentation der Ergebnisse vor Abteilungsleitungen, Bereichsleitungen und Geschäftsführung"
          }
        ],
        "techs": [
          {
            "tech": "Aris Business Designer,"
          },
          {
            "tech": "BPMN"
          },
          {
            "tech": "JIRA"
          },
          {
            "tech": "Confluence"
          }
        ]
      },
      {
        "title": "Steuerung eines Scrum Entwicklungsteam zur Entwicklung des Soll Prozesses und nötigen Softwarekomponenten",
        "from": "05/2016",
        "to": "04/2017 ",
        "industry": "Öffentlicher Dienst",
        "roles": [
          {
            "role": "Product Owner",
            "role": "Requirements Engineer"
          }
        ],
        "description": "In diesem Projekt soll einer der Kernprozesse der KV Hessen von Grund auf überdacht, erneuert und digitalisiert werden, um Altlasten und Medienbrüche zu beseitigen. Ebenso sollen Fehler durch menschlichen Eingriff minimiert und der Ablauf beschleunigt werden. In diesem Prozess fließen Quartalsweise ca. 450 Millionen € von den Krankenkassen über die KV-Hessen zu den Kassenärzten in Hessen. Der Prozess sichert die Vergütung der Leistungen, die an den Patienten in einem Quartal erbracht werden. Im ersten Schritt müssen die eingereichten Leistungen in Form einer Abrechnungsdatei syntaktisch und semantisch geprüft werden. Danach folgt die Validierung der Leistungen, welche nach strengen Regeln abläuft, die sich alle Quartale ändern können. Dazu werden verschiedenen und sehr komplexe Regelwerk aufgesetzt. Nach erfolgreichem Durchlauf der verschiedenen Prozesse müssen die validen Leistungen in Geld umgewandelt werden, was nach fixen und variablen Budgets geschieht. Dies ist ebenfalls sehr komplex. Nach dem Durchlaufen der verschiedenen Validierungen und Budgetierungen wird das errechnete Geld ausgezahlt.",
        "tasks": [
          {
            "task": "Organisation und Führen von Workshops mit diversen Fachbereichen des Unternehmens und externen Beratern"
          },
          {
            "task": "Erarbeiten von Softwareanforderungen und UX mit Fachbereichen"
          },
          {
            "task": "Übersetzung der Anforderungen in User-Stories für ein Scrum Backlog"
          },
          {
            "task": "Erstellung von Wireframes/Mockups für das UX und Frontend"
          },
          {
            "task": "Priorisierung und Pflege eine Backlogs"
          },
          {
            "task": "Weitere Absprachen mit Stakeholdern"
          }
        ],
        "techs": [
          {
            "tech": "Aris Business Designer,"
          },
          {
            "tech": "BPMN"
          },
          {
            "tech": "JIRA"
          },
          {
            "tech": "Confluence"
          }
        ]
      },
      {
        "title": "Entwicklung von Benutzeroberflächen und Endpunkten in einem Scrum Team",
        "from": "05/2017",
        "to": "07/2017 ",
        "industry": "Öffentlicher Dienst",
        "roles": [
          {
            "role": "Software Developer"
          }
        ],
        "description": "In diesem Projekt soll einer der Kernprozesse der KV Hessen von Grund auf überdacht, erneuert und digitalisiert werden, um Altlasten und Medienbrüche zu beseitigen. Ebenso sollen Fehler durch menschlichen Eingriff minimiert und der Ablauf beschleunigt werden. In diesem Prozess fließen Quartalsweise ca. 450 Millionen € von den Krankenkassen über die KV-Hessen zu den Kassenärzten in Hessen. Der Prozess sichert die Vergütung der Leistungen, die an den Patienten in einem Quartal erbracht werden. Im ersten Schritt müssen die eingereichten Leistungen in Form einer Abrechnungsdatei syntaktisch und semantisch geprüft werden. Danach folgt die Validierung der Leistungen, welche nach strengen Regeln abläuft, die sich alle Quartale ändern können. Dazu werden verschiedenen und sehr komplexe Regelwerk aufgesetzt. Nach erfolgreichem Durchlauf der verschiedenen Prozesse müssen die validen Leistungen in Geld umgewandelt werden, was nach fixen und variablen Budgets geschieht. Dies ist ebenfalls sehr komplex. Nach dem Durchlaufen der verschiedenen Validierungen und Budgetierungen wird das errechnete Geld ausgezahlt.",
        "tasks": [
          {
            "task": "Entwicklung von Benutzeroberflächen"
          },
          {
            "task": "Entwicklung von zugehörigen Endpunkten zum nötigen Datenaustausch"
          }
        ],
        "techs": [
          {
            "tech": "Angular 2"
          },
          {
            "tech": "JavaEE"
          },
          {
            "tech": "Visual Code"
          },
          {
            "tech": "Eclipse"
          }
        ]
      },
      {
        "title": "Refactoring einer Web-Applikation zur Integration eines Web-CMS und Back- End-Digital Asset Management",
        "from": "07/2017",
        "to": "08/2017 ",
        "industry": "Sport",
        "roles": [
          {
            "role": "Entwickler"
          },
          {
            "role": "Software Architect"
          }
        ],
        "description" : "Ziel des Projektes ist das Refactoring einer Web-Applikation. Diese ist in ein Digital Asset Management Systems (Celum) integriert und ermöglicht den Zugriff auf Media Assets direkt aus dem Web-Content Management System (Umbraco).",
        "tasks": [
          {
            "task": "Refactoring Backend Schnittstellen"
          },
          {
            "task": "Dokumentation"
          }
        ],
        "techs": [
          {
            "tech": "Java"
          },
          {
            "tech": "Spring"
          },
          {
            "tech": "Eclipse"
          },
          {
            "tech": "Tomcat"
          }
        ]
      },
      {
        "title": "Entwicklung einer weltweiten Konzern-Intranet Web-Application zum Speichern und Verwalten von Suchen im Intranet",
        "from": "07/2017",
        "to": "06/2018 ",
        "industry": "Maschinenbau",
        "roles": [
          {
            "role": "Entwickler"
          }
        ],
        "description": "Ziel des Projektes ist die Entwicklung und der Relaunch einer neuen Web-Application, welche auf der Browser Startseite des Intranets integriert ist. Diese gilt weltweit im Konzern für alle Mitarbeiter und basiert auf Angular 4 und Spring Boot. Die Funktionalitäten werden mittels REST-basierten Backend-Services an Datenquellen angebunden, welche über das Tool abgefragt werden können. Diese Datenquellen beinhalten konzerninterne Applikationen, Medien und Web-Content. Das Tool dient der Abspeicherung von Suchanfragen und visuellen Darstellung und Anordnung der zugehörigen Ergebnisse. Die Ergebnisse können über Drag and Drop angeordnet und sortiert werden. Außerdem ist eine Kategorisierung und Benamung möglich. Integrierte Hinweise und Animation führen den Benutzer durch das Tool, um die Anwendung zu erleichtern. Die Entwicklung erfolgt nach strengen Designvorgaben einer externen Agentur, welche unterschiedliche Designs für Geräte verschiedenerer Größen wie Smartphones, Tablets und Desktop Geräten vorgibt.",
        "tasks": [
          {
            "task": "responsive HTML 5 / CSS Entwicklung basierend auf Frontend-Konzept und Design-Entwurf"
          },
          {
            "task": "Implementierung innovativer Frontend-Funktionalitäten mittels Angular 4"
          },
          {
            "task": "Ableitung von technischen Lösungsansätzen basierend auf Funktionsanforderungen"
          },
          {
            "task": "Abschätzung von Aufwänden für Change Requests"
          },
          {
            "task": "Laufende Abstimmung im Frontend-Entwicklungs-Team (Dailys, Rückfragen zu Backlog items, etc.)"
          }
        ],
        "techs": [
          {
            "tech": "Angular 4"
          },
          {
            "tech": "HTML5"
          },
          {
            "tech": "CSS"
          },
          {
            "tech": "Visual Code"
          }
        ]
      },
      {
        "title": "Entwicklung der Celum DAM Feature-Extension „Assetpicker Cropping“",
        "from": "04/2018",
        "to": "07/2018 ",
        "industry": "Sport",
        "roles": [
          {
            "role": "Entwickler"
          },
          {
            "role": "Software Architect"
          }
        ],
        "description": "Ziel des Projektes ist die Entwicklung einer Spring Boot Applikation, welche als Erweiterung für das Celum DAM System dienen soll. Mit dieser Erweiterung kann das DAM Cropping Anfragen erhalten und Bilder nach Bedarf zuschneiden. Dem Zuschnitte werden über JSON Dateien vorkonfiguriert, um Asset abhängige Anforderungen zu erfüllen. Die Funktionalität wird über REST Endpunkte bereitgestellt. Außerdem müssen die fertigen Derivate in einen Microsoft Blob Storage geladen werden.",
        "tasks": [
          {
            "task": "Entwicklung einer Konfiguration für das Croppen von Assets"
          },
          {
            "task": "Entwicklung der Cropping Funktionalität über Imagemagick"
          },
          {
            "task": "Entwicklung von REST Endpunkten für das Auslösen der Cropping Prozesse"
          }
        ],
        "techs": [
          {
            "tech": "Java"
          },
          {
            "tech": "Spring Boot"
          },
          {
            "tech": "Imagemagick"
          }
        ]
      },
      {
        "title": "Entwicklung einer komplexen Web-Applikation für die Visualisierung der Besteuerung des Warenbestandes",
        "from": "07/2018",
        "to": "10/2018 ",
        "industry": "Möbelindustrie",
        "roles": [
          {
            "role": "Entwickler"
          }
        ],
        "description": "Ziel des Projektes ist die anfallende Versteuerung des Lagerbestandes von Produkten zu ermitteln. Dazu werden verschiedene Berechnungen auf einer Datenbank durchgeführt. Benutzer können über eine Frontend Konfigurationen vornehmen, sowie die Ergebnisse und deren Korrekturen und Stammdaten betrachten.",
        "tasks": [
          {
            "task": "Entwicklung einer Angular Applikation und zugehörigen komplexen Oberflächen für die Anzeigen von Berechnungsergebnissen"
          },
          {
            "task": "Entwicklung von REST Endpunkten für die Verteilung von Berechnungsergebnissen"
          },
          {
            "task": "Abfragen der der Berechnungsergebnisse von einer Datenbank"
          }
        ],
        "techs": [
          {
            "tech": "Angular 6"
          },
          {
            "tech": "Visual Code"
          },
          {
            "tech": ".Net Core"
          },
          {
            "tech": "Visual Studio"
          },
          {
            "tech": "HTML5"
          },
          {
            "tech": "CSS"
          },
          {
            "tech": "Oracle SQL"
          },
          {
            "tech": "Jira"
          },
          {
            "tech": "Confluence"
          },
          {
            "tech": "Scrum"
          }
        ]
      },
      {
        "title": "Entwicklung eines neuen Backends für die Anzeige von Produktdaten",
        "from": "11/2018",
        "to": "03/2019 ",
        "industry": "Maschinenbau",
        "roles": [
          {
            "role": "Entwickler"
          },
          {
            "role": "Software Architect"
          },
          {
            "role": "Projektmanagement"
          }
        ],
        "description": "Ziel des Projektes ist die Bereitstellung von Daten eines komplexen Produktkatlogs für eine neue Homepage. Die Produktdaten, die in Form von mehreren XML verfügbar sind, werden eingelesen und einer Elasticsearch übergeben, um die Daten zu persistieren. Nach der Persistierung werden die Produktdaten gezielt von der neuen Homepage abgefragt, um diese letztendlich anzeigen zu können. Dazu müssen REST Endpunkte im Backend implementiert werden. Außerdem sollen spezifische Suchen wie zum Beispiel Filterung über die Produktdaten möglich sein.",
        "tasks": [
          {
            "task": "Entwicklung eines Importers für komplexe XML Produktkataloge"
          },
          {
            "task": "Entwicklung von REST Endpunkten für die spezifische Abfrage von Produktdaten"
          },
          {
            "tech": "Entwicklung einer Schnittstelle für die Verwendung einer ElasticSearch"
          }
        ],
        "techs": [
          {
            "tech": "SpringBoot"
          },
          {
            "tech": "Eclipse"
          },
          {
            "tech": "ElasticSearch"
          },
          {
            "tech": "Scrum"
          }
        ]
      },
      {
        "title": "Entwicklung eines Portals für die Zusammenarbeit zwischen Freiberufler und Vermittler",
        "from": "04/2019",
        "to": "07/2019 ",
        "industry": "Beratung",
        "roles": [
          {
            "role": "Entwickler"
          }
        ],
        "description": "In diesem Projekt wird eine Schnittstelle zu einem Microsoft Dynamics System in Form einer Angular 8 Applikation entwickelt. Diese Schnittstelle dient als Portal, in dem Freelancer und Job Vermittler miteinander in Kontakt treten können. Freelancer agieren über ein eigenes Profil, welches angelegt werden kann. Vermittler positionieren offene Jobs, auf diese sich Freelancer in dem Portal bewerben können.",
        "tasks": [
          {
            "task": "Erstellung von Mockups basierend auf Kundenanforderungen"
          },
          {
            "task": "Entwicklung einer Angular 8 Portalanwendung mit diversen Interaktionsmöglichkeiten für die Eingabe von Stammdaten und Uploadmöglichkeiten für Dateien wie Bilder und Dokumenten"
          },
          {
            "task": "Entwicklung von Schnittstellen für die Verarbeitung der des Datenaustausches zwischen Front und Backend"
          }
        ],
        "techs": [
          {
            "tech": "Angular 8"
          },
          {
            "tech": "HTML5"
          },
          {
            "tech": "CSS"
          },
          {
            "tech": ".Net"
          }
        ]
      },
      {
        "title": "Weiterentwicklung und Pflege einer Suite (CMS) zur Verwaltung, Konfiguration und Rollout von Hotspots in diversen Kundenszenarien",
        "from": "08/2019",
        "to": "12/2019 ",
        "industry": "Telekommunikation",
        "roles": [
          {
            "role": "Entwickler"
          }
        ],
        "description": "In diesem Projekt wird eine Software Suite gepflegt und weiterentwickelt, mit der Hotspots kundenspezifisch konfiguriert und ausgerollt werden können. Kunden können über das CMS das Hotspot Portal in einem WYSIWG Editor erstellen, abspeichern und wiederum ihren Kunden zu Verfügung stellen. Anforderungen werden mittels eines Scrum Teams umgesetzt.",
        "tasks": [
          {
            "task": "Weiterentwicklung der Angular 8 Applikation"
          },
          {
            "task": "Weiterentwicklung von Backend Schnittstellen zur Verteilung der Daten"
          },
          {
            "task": "Bugfixing"
          }
        ],
        "techs": [
          {
            "tech": "Angular 8"
          },
          {
            "tech": "Angular JS"
          },
          {
            "tech": "HTML5"
          },
          {
            "tech": "CSS"
          },
          {
            "tech": "Spring Boot"
          },
          {
            "tech": "Java"
          },
          {
            "tech": "Scrum"
          }
        ]
      },
      {
        "title": "Entwicklung eines Social Media Bots zur Analyse von Posts über Ai Services",
        "from": "01/2020",
        "to": "03/2020 ",
        "industry": "Telekommunikation",
        "roles": [
          {
            "role": "Entwickler"
          },
          {
            "role": "Software Architect"
          }
        ],
        "description" : "In diesem Projekt wird ein (vorerst) Twitter Bot an Posts mit speziellen Kriterien subscribed. Diese Posts werden auf ihre Stimmung mit AI Services geprüft und abgespeichert. Die abgespeicherten Ergebnisse werden über eine React Applikation visualisiert. Jegliche Bausteine der Architektur sind in Docker Container ausgelagert.",
        "tasks": [
          {
            "task": "Implementierung einer verteilten Docker Architektur"
          },
          {
            "task": "Implementierung verschiedenen Node.js Backends in Docker Containern"
          },
          {
            "task": "Implantierung eines Twitterbots innerhalb dieser Architektur"
          },
          {
            "task": "Implementierung der AI Service Kommunikation innerhalb dieser Architektur"
          },
          {
            "task": "Implementierung der MongoDB innerhalb dieser Architektur"
          }
        ],
        "techs": [
          {
            "tech": "Node.js"
          },
          {
            "tech": "React.js"
          },
          {
            "tech": "HTML5"
          },
          {
            "tech": "CSS"
          },
          {
            "tech": "MongoDB"
          },
          {
            "tech": "Docker"
          },
          {
            "tech": "Docker-Compose"
          },
          {
            "tech": "IBM Watson Cloud Services"
          }
        ]
      },
      {
        "title": "Weiterentwicklung des ICE Portals",
        "from": "05/2020",
        "to": "04/2021 ",
        "industry": "Reise und Verkehr",
        "roles": [
          {
            "role": "Entwickler"
          }
        ],
        "description": "Ziel des Projektes ist die Entwicklung und Weiterentwicklung des ICE Portals. Das ICE Portal ist eine komplexe Kunden Information und Entertainment Portalanwendung. Der Kunde erhält hier die Möglichkeit aktuelle Nachrichten, Magazine und Tageszeitungen zu lesen, Filme und Spiele zu konsumieren. Außerdem integriert das Portal Services für die Bestellung von Essen und Getränken an den Platz. Ebenfalls kann er sich über Points of Interests entlang seiner Reisestrecke informieren und weitere Statusmeldungen über seine geplante Route erhalten. Das Portal wird stetig weiterentwickelt, um den Fahrgast die Fahrt im ICE so angenehm wie möglich zu gestalten. Alle Designvorgaben kommen von einer externen Agentur und beinhalten Vorgaben für Geräte wie Smartphones, Tablets und Desktop Geräten.",
        "tasks": [
          {
            "task": "Weiterentwicklung der Angular 10 Applikation"
          },
          {
            "task": "Abstimmungen mit externen Designern und Kunde"
          },
          {
            "task": "Bugfixing"
          }
        ],
        "techs": [
          {
            "tech": "Angular 10"
          },
          {
            "tech": "HTML5"
          },
          {
            "tech": "CSS"
          },
          {
            "tech": "NGRX"
          }
        ]
      },
      {
        "title": "Weiterentwicklung eines Ein und Auszahlungssystems für Urlaubsgeld und Urlaubstage für das Baugewerbe",
        "from": "05/2021",
        "to": "04/2022 ",
        "industry": "Bauhauptgewerbe",
        "roles": [
          {
            "role": "Entwickler"
          }
        ],
        "description": "Ziel des Projektes ist die Entwicklung und Weiterentwicklung eines Prozesses für die Errechnung von verfügbareben Urlaubstagen und zugehörigem Urlaubsgeld für die Beschäftigten im Baugewerbe. Dazu verarbeitet der Prozess monatliche Meldungen der Arbeitgeber im Baugewerbe und validiert diese zusätzlich. Auftretende Unstimmigkeiten werden von Sachbearbeitern mit einer speziellen GUI geprüft und bearbeitet, um den Prozess letztendlich sachgerecht beenden zu können.",
        "tasks": [
          {
            "task": "Pflege und Erweiterung von BPMN Prozesse"
          },
          {
            "task": "Entwicklung von ServiceTasks für BPMN Prozesse"
          },
          {
            "task": "Stammdatenpflege mit SQL"
          },
          {
            "task": "Refactorings"
          },
          {
            "task": "Bugfixing"
          }
        ],
        "techs": [
          {
            "tech": "JavaEE"
          },
          {
            "tech": "Camunda"
          },
          {
            "tech": "JBoss"
          },
          {
            "tech": "Maven"
          },
          {
            "tech": "Swagger"
          },
          {
            "tech": "IBM DB2"
          },
          {
            "tech": "SQL"
          }
        ]
      },
      {
        "title": "Weiterentwicklung eines Portales für den monatlichen Payroll Prozess im HR Bereich",
        "from": "05/2022",
        "to": "09/2022 ",
        "industry": "Lohn und Gehalt",
        "roles": [
          {
            "role": "Entwickler"
          }
        ],
        "description": "Ziel des Projektes ist die Entwicklung und Weiterentwicklung eines Portals, dass den monatlichen Payroll Prozess für den HR-Bereich ermöglicht. Das Portal wird von Firmen direkt, oder in Vertretung von Steuerberatern benutzt. Im Portal wird die Firma und dessen Personalstruktur angelegt und unter gesetzlichen Bedingungen der monatliche Abrechnungsprozess ausgeführt, der zur Lohnauszahlung führt. Am Ende einer Abrechnungsperiode werden zugehörige Reports angelegt und bei Bedarf versendet.",
        "tasks": [
          {
            "task": "Refactoring und von bestehenden Java Code für Reports"
          },
          {
            "task": "Entwicklung von neuen Backend Java Code für Reports"
          },
          {
            "task": "Report Frontend Erstellung mit PUG, HTML und CSS"
          }
        ],
        "techs": [
          {
            "tech": "Java"
          },
          {
            "tech": "PUG"
          },
          {
            "tech": "Maven"
          },
          {
            "tech": "Swagger"
          },
          {
            "tech": "HTML"
          },
          {
            "tech": "CSS"
          }
        ]
      }
    ]
  }`;