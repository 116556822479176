<template>
  <article>
    <details class="projekt-details">
      <summary class="projekt-details-summary">
        <div class="projekt-details-summary-header">
          <h3>{{ this.item.title }}</h3>
        </div>
        <div class="projekt-details-summary-button">
          <span class="projekt-details-summary-button-wrapper">
            <button>Öffnen und Schließen</button>
          </span>
        </div>
      </summary>
      <dl>
        <dt>Start</dt>
        <dd>
          <time>{{ item.from }}</time>
        </dd>
        <dt>Ende</dt>
        <dd>{{ this.item.to }}</dd>
        <dt>Branche</dt>
        <dd>{{ this.item.industry }}</dd>
        <dt>Rolle</dt>
        <ddElement
          v-for="role in this.item.roles"
          :key="role.role"
          v-bind:ddContent="role.role"
        ></ddElement>
        <dt>Technologien und Tools</dt>
        <ddElement
          v-for="tech in this.item.techs"
          :key="tech.tech"
          v-bind:ddContent="tech.tech"
        ></ddElement>
        <dt>Beschreibung</dt>
        <dd>{{ this.item.description }}</dd>
        <dt>Aufgaben</dt>
        <ddElement
          v-for="task in this.item.tasks"
          :key="task.task"
          v-bind:ddContent="task.task"
        ></ddElement>
      </dl>
    </details>
  </article>
</template>

<script>
import ddElement from "./DDElement";

export default {
  name: "ProjectItem",
  props: ["item"],
  components: {
    ddElement,
  },
};
</script>

<style scoped>
</style>