
<template>
  <div class="projekte-container">
    <ProjectItem
      v-for="(item, index) in parseProjects"
      v-bind:key="index"
      v-bind:item="item"
    ></ProjectItem>
  </div>
</template>

<script>
import ProjectItem from "./ProjectItem";
import { projects } from "../js/projects";

export default {
  name: "ProjectsList",
  components: {
    ProjectItem,
  },
  computed: {
    parseProjects: () => {
      const obj = JSON.parse(projects);
      return obj.projects.reverse();
    },
  },
};
</script>